import * as React from "react";

import { Link } from "gatsby";
import { SEOPagesDescription } from "../utils/seo.util";
import Seo from "../components/SEO/Seo";
import { withLayout } from "../components/Layout";

const ThankYouPage = () => (
  <React.Fragment>
    <Seo {...SEOPagesDescription["thank-you"]} />
    <div
      style={{
        minHeight: "calc(100vh - 39px)",
        background: "#5a8926",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fbed21",
      }}
    >
      <div>
        <h1 style={{ margin: 0 }}>Thank you for your message.</h1>
        <h2>We should be in touch soon</h2>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <p style={{ color: "#fff" }}>Back to the site!</p>
        </Link>
      </div>
    </div>
  </React.Fragment>
);
export default withLayout(ThankYouPage);
